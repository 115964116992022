import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
    AcademicCapIcon, 
    UserCircleIcon, 
    ArrowRightOnRectangleIcon,
    VideoCameraIcon,
    FolderIcon,
    ChatBubbleLeftIcon,
    UserGroupIcon,
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon
} from '@heroicons/react/24/outline';
import axios from '../utils/axios';

function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user') || '{}');
    const isAdmin = user?.isAdmin;
    const [isProfileOpen, setIsProfileOpen] = useState(false);

    const handleLogout = async () => {
        try {
            await axios.post('/auth/logout').catch(() => {});
        } finally {
            localStorage.clear();
            sessionStorage.clear();
            delete axios.defaults.headers.common['Authorization'];
            navigate('/login');
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleForward = () => {
        navigate(1);
    };

    const containerVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                duration: 0.5,
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, x: -20 },
        visible: { opacity: 1, x: 0 },
        hover: { scale: 1.05, transition: { duration: 0.2 } },
        tap: { scale: 0.95 }
    };

    const truncateEmail = (email) => {
        if (!email) return '';
        const [username, domain] = email.split('@');
        if (username.length > 10) {
            return `${username.slice(0, 10)}...@${domain}`;
        }
        return email;
    };

    return (
        <motion.header 
            className="bg-gradient-to-r from-primary-600 via-primary-700 to-primary-800 shadow-lg"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    <div className="flex items-center space-x-4">
                        <motion.button
                            onClick={handleBack}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="p-1 rounded-full text-white hover:bg-primary-500 transition-colors"
                            title="Geri Git"
                        >
                            <ArrowLeftCircleIcon className="h-6 w-6" />
                        </motion.button>
                        <motion.button
                            onClick={handleForward}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="p-1 rounded-full text-white hover:bg-primary-500 transition-colors"
                            title="İleri Git"
                        >
                            <ArrowRightCircleIcon className="h-6 w-6" />
                        </motion.button>
                        <motion.div 
                            className="flex-shrink-0 flex items-center"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Link to="/" className="flex items-center space-x-2">
                                <motion.div
                                    animate={{ 
                                        rotate: [0, -10, 10, -10, 0],
                                        transition: { 
                                            duration: 2,
                                            repeat: Infinity,
                                            repeatType: "reverse"
                                        }
                                    }}
                                >
                                    <AcademicCapIcon className="h-8 w-8 text-white" />
                                </motion.div>
                                <span className="text-xl font-bold text-white">Knowhy Akademi</span>
                            </Link>
                        </motion.div>
                    </div>

                    <div className="flex items-center space-x-4">
                        <AnimatePresence mode="wait">
                            {isAdmin ? (
                                <motion.div
                                    key="admin-menu"
                                    className="hidden md:flex items-center space-x-4"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -20 }}
                                >
                                    <motion.div
                                        variants={itemVariants}
                                        whileHover="hover"
                                        whileTap="tap"
                                    >
                                        <Link 
                                            to="/admin/videos"
                                            className="flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-primary-500 transition-colors"
                                        >
                                            <VideoCameraIcon className="h-5 w-5" />
                                            <span>Video Yönetimi</span>
                                        </Link>
                                    </motion.div>

                                    <motion.div
                                        variants={itemVariants}
                                        whileHover="hover"
                                        whileTap="tap"
                                    >
                                        <Link 
                                            to="/admin/categories"
                                            className="flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-primary-500 transition-colors"
                                        >
                                            <FolderIcon className="h-5 w-5" />
                                            <span>Kategori Yönetimi</span>
                                        </Link>
                                    </motion.div>

                                    <motion.div
                                        variants={itemVariants}
                                        whileHover="hover"
                                        whileTap="tap"
                                    >
                                        <Link 
                                            to="/admin/comments"
                                            className="flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-primary-500 transition-colors"
                                        >
                                            <ChatBubbleLeftIcon className="h-5 w-5" />
                                            <span>Yorum Yönetimi</span>
                                        </Link>
                                    </motion.div>

                                    <motion.div
                                        variants={itemVariants}
                                        whileHover="hover"
                                        whileTap="tap"
                                    >
                                        <Link 
                                            to="/admin/users"
                                            className="flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-primary-500 transition-colors"
                                        >
                                            <UserGroupIcon className="h-5 w-5" />
                                            <span>Kullanıcı Yönetimi</span>
                                        </Link>
                                    </motion.div>
                                </motion.div>
                            ) : null}

                            {user?.email ? (
                                <motion.div
                                    key="user-menu"
                                    className="flex items-center space-x-2"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -20 }}
                                >
                                    <motion.div
                                        variants={itemVariants}
                                        className="relative"
                                    >
                                        <motion.button
                                            onClick={() => setIsProfileOpen(!isProfileOpen)}
                                            className="flex items-center space-x-2 px-3 py-2 rounded-full bg-primary-500/30 hover:bg-primary-500/50 transition-colors"
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                        >
                                            <UserCircleIcon className="h-5 w-5 text-white" />
                                            <span className="text-sm font-medium text-white hidden sm:inline">
                                                {truncateEmail(user.email)}
                                            </span>
                                        </motion.button>

                                        <AnimatePresence>
                                            {isProfileOpen && (
                                                <motion.div
                                                    initial={{ opacity: 0, y: -10 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    exit={{ opacity: 0, y: -10 }}
                                                    className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                                                >
                                                    <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-100">
                                                        {user.email}
                                                    </div>
                                                    <button
                                                        onClick={handleLogout}
                                                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                                                    >
                                                        <ArrowRightOnRectangleIcon className="h-5 w-5" />
                                                        <span>Çıkış Yap</span>
                                                    </button>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </motion.div>
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="login-menu"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -20 }}
                                >
                                    <Link 
                                        to="/login"
                                        className="flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-primary-500 transition-colors"
                                    >
                                        <UserCircleIcon className="h-5 w-5" />
                                        <span>Giriş Yap</span>
                                    </Link>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>
            </nav>
        </motion.header>
    );
}

export default Header; 