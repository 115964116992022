import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Switch,
    FormControlLabel,
    Chip,
    Tooltip,
    Grid,
    Alert,
    Snackbar,
    InputLabel,
    Select,
    MenuItem,
    FormControl
} from '@mui/material';
import {
    Edit,
    Delete,
    Visibility,
    VisibilityOff,
    ThumbUp,
    RemoveRedEye,
    Comment as CommentIcon
} from '@mui/icons-material';
import axios from '../utils/axios';
import { Link } from 'react-router-dom';

const VideoManagementPage = () => {
    const [videos, setVideos] = useState([]);
    const [editDialog, setEditDialog] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [editForm, setEditForm] = useState({
        title: '',
        description: '',
        isActive: true,
        category: ''
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [categories, setCategories] = useState([]);

    const fetchVideos = async () => {
        try {
            const response = await axios.get('/videos/admin');
            if (response.data) {
                setVideos(response.data);
            }
        } catch (error) {
            console.error('Videolar yüklenirken hata oluştu:', error);
            showSnackbar('Videolar yüklenirken bir hata oluştu', 'error');
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get('/categories');
            setCategories(response.data);
        } catch (error) {
            console.error('Kategoriler yüklenirken hata oluştu:', error);
        }
    };

    useEffect(() => {
        fetchVideos();
        fetchCategories();
    }, []);

    const handleEditClick = (video) => {
        setSelectedVideo(video);
        setEditForm({
            title: video.title,
            description: video.description || '',
            isActive: video.isActive,
            category: video.category?._id || ''
        });
        setEditDialog(true);
    };

    const handleEditSubmit = async () => {
        try {
            const response = await axios.put(`/videos/${selectedVideo._id}`, editForm);
            if (response.data.success) {
                setVideos(videos.map(video => 
                    video._id === selectedVideo._id ? response.data.video : video
                ));
                setEditDialog(false);
                showSnackbar('Video başarıyla güncellendi', 'success');
            }
        } catch (error) {
            console.error('Video güncellenirken hata oluştu:', error);
            showSnackbar('Video güncellenirken bir hata oluştu', 'error');
        }
    };

    const handleDeleteVideo = async (videoId) => {
        if (window.confirm('Bu videoyu ve ilgili tüm içerikleri (yorumlar vb.) silmek istediğinizden emin misiniz?')) {
            try {
                const response = await axios.delete(`/videos/${videoId}`);
                if (response.data.success) {
                    setVideos(videos.filter(video => video._id !== videoId));
                    showSnackbar('Video ve ilgili içerikler başarıyla silindi', 'success');
                }
            } catch (error) {
                console.error('Video silinirken hata oluştu:', error);
                showSnackbar('Video silinirken bir hata oluştu', 'error');
            }
        }
    };

    const handleToggleStatus = async (videoId, currentStatus) => {
        try {
            const response = await axios.patch(`/api/videos/${videoId}/toggle-status`, {
                isActive: !currentStatus
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            
            if (response.data) {
                setVideos(videos.map(video => 
                    video._id === videoId ? { ...video, isActive: !currentStatus } : video
                ));
                showSnackbar(
                    `Video ${currentStatus ? 'devre dışı bırakıldı' : 'aktifleştirildi'}`,
                    'success'
                );
            }
        } catch (error) {
            console.error('Video durumu değiştirilirken hata oluştu:', error);
            showSnackbar('Video durumu değiştirilirken bir hata oluştu', 'error');
        }
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                <Typography variant="h4">Video Yönetimi</Typography>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/admin/videos/upload"
                        sx={{ mr: 2 }}
                    >
                        Yeni Video Yükle
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={fetchVideos}
                    >
                        Listeyi Yenile
                    </Button>
                </Box>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Başlık</TableCell>
                            <TableCell>Açıklama</TableCell>
                            <TableCell>Yüklenme Tarihi</TableCell>
                            <TableCell>İstatistikler</TableCell>
                            <TableCell>Durum</TableCell>
                            <TableCell>İşlemler</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {videos.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    <Typography variant="body1">Henüz video yüklenmemiş</Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            videos.map((video) => (
                                <TableRow key={video._id}>
                                    <TableCell>
                                        <Box>
                                            <Typography variant="subtitle2">
                                                {video.title}
                                            </Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {video.category?.name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {video.description?.substring(0, 100)}
                                            {video.description?.length > 100 ? '...' : ''}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            <Typography variant="body2">
                                                {formatDate(video.createdAt)}
                                            </Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                Yükleyen: {video.uploadedBy?.name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <Chip
                                                    icon={<RemoveRedEye />}
                                                    label={`${video.views} görüntülenme`}
                                                    size="small"
                                                    color="primary"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Chip
                                                    icon={<ThumbUp />}
                                                    label={`${video.likes?.length || 0} beğeni`}
                                                    size="small"
                                                    color="secondary"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Chip
                                                    icon={<CommentIcon />}
                                                    label={`${video.commentCount || 0} yorum`}
                                                    size="small"
                                                    color="info"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            <Tooltip title={video.isActive ? 'Devre Dışı Bırak' : 'Aktifleştir'}>
                                                <IconButton
                                                    onClick={() => handleToggleStatus(video._id, video.isActive)}
                                                    color={video.isActive ? "success" : "error"}
                                                    size="small"
                                                >
                                                    {video.isActive ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </Tooltip>
                                            {video.statusChangedBy && (
                                                <Typography variant="caption" display="block" color="textSecondary">
                                                    Son değişiklik: {formatDate(video.statusChangedAt)}
                                                </Typography>
                                            )}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            <Tooltip title="Düzenle">
                                                <IconButton
                                                    onClick={() => handleEditClick(video)}
                                                    color="primary"
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sil">
                                                <IconButton
                                                    onClick={() => handleDeleteVideo(video._id)}
                                                    color="error"
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="İzle">
                                                <IconButton
                                                    component={Link}
                                                    to={`/videos/${video._id}`}
                                                    color="info"
                                                >
                                                    <RemoveRedEye />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={editDialog} onClose={() => setEditDialog(false)}>
                <DialogTitle>Video Düzenle</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Başlık"
                        type="text"
                        fullWidth
                        value={editForm.title}
                        onChange={(e) => setEditForm({ ...editForm, title: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Açıklama"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={editForm.description}
                        onChange={(e) => setEditForm({ ...editForm, description: e.target.value })}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Kategori</InputLabel>
                        <Select
                            value={editForm.category}
                            onChange={(e) => setEditForm({ ...editForm, category: e.target.value })}
                        >
                            {categories.map((category) => (
                                <MenuItem key={category._id} value={category._id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={editForm.isActive}
                                onChange={(e) => setEditForm({ ...editForm, isActive: e.target.checked })}
                                color="primary"
                            />
                        }
                        label="Video Aktif"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialog(false)} color="inherit">
                        İptal
                    </Button>
                    <Button onClick={handleEditSubmit} color="primary" variant="contained">
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default VideoManagementPage; 