import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from '../utils/axios';
import { Link } from 'react-router-dom';
import { PlayIcon, ClockIcon, StarIcon } from '@heroicons/react/24/outline';

function HomePage() {
    const [videos, setVideos] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [videosRes, categoriesRes] = await Promise.all([
                    axios.get('/videos'),
                    axios.get('/categories')
                ]);
                setVideos(videosRes.data);
                setCategories(categoriesRes.data);
            } catch (error) {
                console.error('Veri yükleme hatası:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const filteredVideos = selectedCategory === 'all' 
        ? videos 
        : videos.filter(video => video.category?._id === selectedCategory);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="loading-spinner" />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* Hero Section */}
                <motion.div 
                    className="text-center mb-12"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">
                        Knowhy Akademi'ye Hoş Geldiniz
                    </h1>
                    <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                        Yeni nesil eğitim platformunda kendinizi geliştirin
                    </p>
                </motion.div>

                {/* Kategori Filtreleri */}
                <motion.div 
                    className="mb-8 flex flex-wrap justify-center gap-2"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >
                    <motion.button
                        variants={itemVariants}
                        className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                            selectedCategory === 'all'
                            ? 'bg-primary-600 text-white shadow-lg'
                            : 'bg-white text-gray-700 hover:bg-primary-50'
                        }`}
                        onClick={() => setSelectedCategory('all')}
                    >
                        Tümü
                    </motion.button>
                    {categories.map(category => (
                        <motion.button
                            key={category._id}
                            variants={itemVariants}
                            className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                                selectedCategory === category._id
                                ? 'bg-primary-600 text-white shadow-lg'
                                : 'bg-white text-gray-700 hover:bg-primary-50'
                            }`}
                            onClick={() => setSelectedCategory(category._id)}
                        >
                            {category.name}
                        </motion.button>
                    ))}
                </motion.div>

                {/* Video Grid */}
                <motion.div 
                    className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >
                    {filteredVideos.map(video => (
                        <motion.div
                            key={video._id}
                            variants={itemVariants}
                            className="group"
                        >
                            <Link to={`/video/${video._id}`} className="block">
                                <div className="bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl">
                                    <div className="relative">
                                        <div className="aspect-w-16 aspect-h-9">
                                            <img 
                                                src={video.thumbnailUrl || 'https://via.placeholder.com/640x360'} 
                                                alt={video.title}
                                                className="object-cover w-full h-full"
                                            />
                                        </div>
                                        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300 flex items-center justify-center">
                                            <PlayIcon className="w-16 h-16 text-white opacity-0 group-hover:opacity-100 transition-all duration-300" />
                                        </div>
                                    </div>
                                    <div className="p-4">
                                        <h3 className="text-lg font-semibold text-gray-900 mb-2 line-clamp-2">
                                            {video.title}
                                        </h3>
                                        <p className="text-sm text-gray-600 mb-4 line-clamp-2">
                                            {video.description}
                                        </p>
                                        <div className="flex items-center justify-between text-sm text-gray-500">
                                            <div className="flex items-center space-x-1">
                                                <ClockIcon className="w-4 h-4" />
                                                <span>{video.duration || '00:00'}</span>
                                            </div>
                                            <div className="flex items-center space-x-1">
                                                <StarIcon className="w-4 h-4" />
                                                <span>{video.rating || '4.5'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </motion.div>
                    ))}
                </motion.div>

                {/* Boş Durum */}
                {filteredVideos.length === 0 && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="text-center py-12"
                    >
                        <p className="text-gray-600 text-lg">
                            Bu kategoride henüz video bulunmuyor.
                        </p>
                    </motion.div>
                )}
            </div>
        </div>
    );
}

export default HomePage; 