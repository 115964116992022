import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' 
    ? 'https://knowhy.wiki/api'
    : 'http://localhost:5000/api';

const instance = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Request interceptor - token ekle
instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor - hata yönetimi
instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('isAdmin');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default instance; 