import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { UNSAFE_DataRouterContext, UNSAFE_DataRouterStateContext } from 'react-router-dom';
import axios from './utils/axios';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import VideoPage from './pages/VideoPage';
import VideoUploadPage from './pages/VideoUploadPage';
import VideoManagementPage from './pages/VideoManagementPage';
import CategoryManagementPage from './pages/CategoryManagementPage';
import AdminDashboard from './pages/AdminDashboard';
import CommentManagementPage from './pages/CommentManagementPage';
import UserManagementPage from './pages/UserManagementPage';

const AdminRoute = ({ children }) => {
    const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user') || '{}');
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    
    if (!token || !user || !user.isAdmin) {
        return <Navigate to="/login" />;
    }
    return children;
};

const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (!token) {
        return <Navigate to="/login" />;
    }
    return children;
};

const AuthCheck = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    
    useEffect(() => {
        const verifyAuth = async () => {
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                // Token'ı axios instance'ına ekle
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                
                // Token'ı doğrula
                await axios.get('/auth/verify');
            } catch (error) {
                console.error('Auth check error:', error);
                // Token geçersizse veya süresi dolmuşsa
                localStorage.clear();
                sessionStorage.clear();
                delete axios.defaults.headers.common['Authorization'];
                navigate('/login');
            }
        };

        verifyAuth();
    }, [token, navigate]);
    
    return null;
};

function App() {
    return (
        <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
            <AuthCheck />
            <Header />
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                
                <Route path="/" element={
                    <PrivateRoute>
                        <HomePage />
                    </PrivateRoute>
                } />
                
                <Route path="/video/:id" element={
                    <PrivateRoute>
                        <VideoPage />
                    </PrivateRoute>
                } />

                {/* Admin Routes */}
                <Route path="/admin" element={
                    <AdminRoute>
                        <AdminDashboard />
                    </AdminRoute>
                } />
                
                <Route path="/admin/videos" element={
                    <AdminRoute>
                        <VideoManagementPage />
                    </AdminRoute>
                } />

                <Route path="/admin/videos/upload" element={
                    <AdminRoute>
                        <VideoUploadPage />
                    </AdminRoute>
                } />
                
                <Route path="/admin/categories" element={
                    <AdminRoute>
                        <CategoryManagementPage />
                    </AdminRoute>
                } />

                <Route path="/admin/comments" element={
                    <AdminRoute>
                        <CommentManagementPage />
                    </AdminRoute>
                } />

                <Route path="/admin/users" element={
                    <AdminRoute>
                        <UserManagementPage />
                    </AdminRoute>
                } />

                <Route path="/videos" element={
                    <PrivateRoute>
                        <VideoPage />
                    </PrivateRoute>
                } />
                
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
}

export default App; 