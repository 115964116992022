import React, { useState, useEffect } from 'react';
import {
    Box,
    Drawer,
    AppBar,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Chip,
    Badge,
    FormControlLabel,
    Switch
} from '@mui/material';
import {
    Menu as MenuIcon,
    VideoLibrary,
    Comment,
    Category,
    Edit,
    Delete,
    CheckCircle,
    Cancel,
    Notifications,
    ExitToApp,
    RemoveRedEye
} from '@mui/icons-material';
import { Link, useNavigate, Routes, Route } from 'react-router-dom';
import axios from '../utils/axios';

const drawerWidth = 240;

const AdminDashboard = () => {
    const [open, setOpen] = useState(true);
    const [videos, setVideos] = useState([]);
    const [comments, setComments] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [editDialog, setEditDialog] = useState(false);
    const [editForm, setEditForm] = useState({
        title: '',
        description: '',
        isActive: true
    });
    const navigate = useNavigate();

    useEffect(() => {
        fetchVideos();
        fetchComments();
    }, []);

    const fetchVideos = async () => {
        try {
            const response = await axios.get('/videos/admin');
            setVideos(response.data);
        } catch (error) {
            console.error('Videolar yüklenirken hata oluştu:', error);
        }
    };

    const fetchComments = async () => {
        try {
            const response = await axios.get('/comments/admin');
            setComments(response.data);
        } catch (error) {
            console.error('Yorumlar yüklenirken hata oluştu:', error);
        }
    };

    const handleEditVideo = (video) => {
        setSelectedVideo(video);
        setEditForm({
            title: video.title,
            description: video.description || '',
            isActive: video.isActive
        });
        setEditDialog(true);
    };

    const handleEditSubmit = async () => {
        try {
            await axios.put(`/videos/${selectedVideo._id}`, editForm);
            setEditDialog(false);
            fetchVideos();
        } catch (error) {
            console.error('Video güncellenirken hata oluştu:', error);
        }
    };

    const handleDeleteVideo = async (videoId) => {
        if (window.confirm('Bu videoyu silmek istediğinizden emin misiniz?')) {
            try {
                await axios.delete(`/videos/${videoId}`);
                fetchVideos();
            } catch (error) {
                console.error('Video silinirken hata oluştu:', error);
            }
        }
    };

    const handleToggleVideoStatus = async (videoId) => {
        try {
            await axios.patch(`/videos/${videoId}/toggle-status`);
            fetchVideos();
        } catch (error) {
            console.error('Video durumu değiştirilirken hata oluştu:', error);
        }
    };

    const handleToggleCommentApproval = async (commentId) => {
        try {
            await axios.patch(`/comments/${commentId}/toggle-approval`);
            fetchComments();
        } catch (error) {
            console.error('Yorum onay durumu değiştirilirken hata oluştu:', error);
        }
    };

    const handleDeleteComment = async (commentId) => {
        if (window.confirm('Bu yorumu silmek istediğinizden emin misiniz?')) {
            try {
                await axios.delete(`/comments/${commentId}`);
                fetchComments();
            } catch (error) {
                console.error('Yorum silinirken hata oluştu:', error);
            }
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('isAdmin');
        navigate('/login');
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={() => setOpen(!open)}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        Admin Panel
                    </Typography>
                    <IconButton color="inherit">
                        <Badge badgeContent={comments.filter(c => !c.isApproved).length} color="error">
                            <Notifications />
                        </Badge>
                    </IconButton>
                    <IconButton color="inherit" onClick={handleLogout}>
                        <ExitToApp />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="persistent"
                anchor="left"
                open={open}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        <ListItem button component={Link} to="/admin/videos">
                            <ListItemIcon>
                                <VideoLibrary />
                            </ListItemIcon>
                            <ListItemText primary="Video Yönetimi" />
                        </ListItem>
                        <ListItem button component={Link} to="/admin/comments">
                            <ListItemIcon>
                                <Comment />
                            </ListItemIcon>
                            <ListItemText primary="Yorum Yönetimi" />
                            <Chip
                                size="small"
                                color="error"
                                label={comments.filter(c => !c.isApproved).length}
                            />
                        </ListItem>
                        <ListItem button component={Link} to="/admin/categories">
                            <ListItemIcon>
                                <Category />
                            </ListItemIcon>
                            <ListItemText primary="Kategori Yönetimi" />
                        </ListItem>
                    </List>
                </Box>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        {/* İstatistikler */}
                        <Grid item xs={12} md={4}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 140 }}>
                                <Typography variant="h6" gutterBottom>
                                    Toplam Video
                                </Typography>
                                <Typography component="p" variant="h4">
                                    {videos.length}
                                </Typography>
                                <Typography color="text.secondary" sx={{ flex: 1 }}>
                                    {videos.filter(v => v.isActive).length} aktif video
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 140 }}>
                                <Typography variant="h6" gutterBottom>
                                    Toplam Yorum
                                </Typography>
                                <Typography component="p" variant="h4">
                                    {comments.length}
                                </Typography>
                                <Typography color="text.secondary" sx={{ flex: 1 }}>
                                    {comments.filter(c => !c.isApproved).length} onay bekleyen yorum
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 140 }}>
                                <Typography variant="h6" gutterBottom>
                                    Toplam İzlenme
                                </Typography>
                                <Typography component="p" variant="h4">
                                    {videos.reduce((total, video) => total + (video.views || 0), 0)}
                                </Typography>
                                <Typography color="text.secondary" sx={{ flex: 1 }}>
                                    Son 30 gün
                                </Typography>
                            </Paper>
                        </Grid>

                        {/* Son Eklenen Videolar */}
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                    <Typography variant="h6">Son Eklenen Videolar</Typography>
                                    <Box>
                                        <Button
                                            variant="contained"
                                            component={Link}
                                            to="/admin/videos/upload"
                                            color="primary"
                                            sx={{ mr: 2 }}
                                        >
                                            Yeni Video Yükle
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            component={Link}
                                            to="/admin/videos"
                                            color="primary"
                                        >
                                            Tüm Videoları Yönet
                                        </Button>
                                    </Box>
                                </Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Başlık</TableCell>
                                                <TableCell>Yüklenme Tarihi</TableCell>
                                                <TableCell>Durum</TableCell>
                                                <TableCell>İşlemler</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {videos.slice(0, 5).map((video) => (
                                                <TableRow key={video._id}>
                                                    <TableCell>
                                                        <Typography variant="subtitle2">
                                                            {video.title}
                                                        </Typography>
                                                        <Typography variant="caption" color="textSecondary">
                                                            {video.category?.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatDate(video.createdAt)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            label={video.isActive ? 'Aktif' : 'Pasif'}
                                                            color={video.isActive ? 'success' : 'error'}
                                                            size="small"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            component={Link}
                                                            to={`/admin/videos?edit=${video._id}`}
                                                            color="primary"
                                                            size="small"
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton
                                                            component={Link}
                                                            to={`/video/${video._id}`}
                                                            color="info"
                                                            size="small"
                                                        >
                                                            <RemoveRedEye />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>

                        {/* Onay Bekleyen Yorumlar */}
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                    <Typography variant="h6">Onay Bekleyen Yorumlar</Typography>
                                    <Button
                                        variant="contained"
                                        component={Link}
                                        to="/admin/comments"
                                        color="primary"
                                    >
                                        Tüm Yorumları Yönet
                                    </Button>
                                </Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Kullanıcı</TableCell>
                                                <TableCell>Yorum</TableCell>
                                                <TableCell>Video</TableCell>
                                                <TableCell>Tarih</TableCell>
                                                <TableCell>İşlemler</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {comments.filter(c => !c.isApproved).slice(0, 5).map((comment) => (
                                                <TableRow key={comment._id}>
                                                    <TableCell>
                                                        {comment.user?.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {comment.content?.substring(0, 100)}
                                                        {comment.content?.length > 100 ? '...' : ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        {comment.video?.title}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatDate(comment.createdAt)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            onClick={() => handleToggleCommentApproval(comment._id)}
                                                            color="success"
                                                            size="small"
                                                        >
                                                            <CheckCircle />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => handleDeleteComment(comment._id)}
                                                            color="error"
                                                            size="small"
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Video Düzenleme Dialog */}
            <Dialog open={editDialog} onClose={() => setEditDialog(false)}>
                <DialogTitle>Video Düzenle</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Başlık"
                        type="text"
                        fullWidth
                        value={editForm.title}
                        onChange={(e) => setEditForm({ ...editForm, title: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Açıklama"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={editForm.description}
                        onChange={(e) => setEditForm({ ...editForm, description: e.target.value })}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={editForm.isActive}
                                onChange={(e) => setEditForm({ ...editForm, isActive: e.target.checked })}
                                color="primary"
                            />
                        }
                        label="Video Aktif"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialog(false)} color="inherit">
                        İptal
                    </Button>
                    <Button onClick={handleEditSubmit} color="primary" variant="contained">
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminDashboard; 