import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from '../utils/axios';
import { CloudArrowUpIcon, XCircleIcon } from '@heroicons/react/24/outline';

function VideoUploadPage() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [videoFile, setVideoFile] = useState(null);
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [thumbnailPreview, setThumbnailPreview] = useState('');
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState('');
    const [duration, setDuration] = useState('00:00');
    const [uploadSpeed, setUploadSpeed] = useState(0);
    const [estimatedTime, setEstimatedTime] = useState(0);
    const [uploadStartTime, setUploadStartTime] = useState(null);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get('/categories');
            setCategories(response.data);
        } catch (error) {
            console.error('Kategoriler yüklenemedi:', error);
            setError('Kategoriler yüklenemedi');
        }
    };

    const handleVideoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setVideoFile(file);
            // Video süresini hesapla
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = function() {
                window.URL.revokeObjectURL(video.src);
                const minutes = Math.floor(video.duration / 60);
                const seconds = Math.floor(video.duration % 60);
                setDuration(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
            }
            video.src = URL.createObjectURL(file);
        }
    };

    const handleThumbnailChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setThumbnailFile(file);
            setThumbnailPreview(URL.createObjectURL(file));
        }
    };

    const calculateUploadStats = (progressEvent) => {
        const currentTime = Date.now();
        if (!uploadStartTime) {
            setUploadStartTime(currentTime);
            return;
        }

        const timeElapsed = (currentTime - uploadStartTime) / 1000; // saniye
        const uploadedBytes = progressEvent.loaded;
        const totalBytes = progressEvent.total;
        const remainingBytes = totalBytes - uploadedBytes;
        
        // Yükleme hızı (bytes/saniye)
        const speed = uploadedBytes / timeElapsed;
        setUploadSpeed(speed);

        // Tahmini kalan süre (saniye)
        const estimatedRemainingTime = remainingBytes / speed;
        setEstimatedTime(estimatedRemainingTime);
    };

    const formatBytes = (bytes) => {
        if (bytes === 0) return '0 B';
        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const formatTime = (seconds) => {
        if (!seconds || seconds === Infinity) return 'hesaplanıyor...';
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!videoFile || !title || !category) {
            setError('Lütfen gerekli alanları doldurun');
            return;
        }

        setUploading(true);
        setError('');
        setUploadStartTime(Date.now());
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('category', category);
        formData.append('video', videoFile);
        formData.append('duration', duration);
        if (thumbnailFile) {
            formData.append('thumbnail', thumbnailFile);
        }

        try {
            const response = await axios.post('/videos', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                    calculateUploadStats(progressEvent);
                }
            });

            if (response.data) {
                // Form temizleme
                setTitle('');
                setDescription('');
                setCategory('');
                setVideoFile(null);
                setThumbnailFile(null);
                setThumbnailPreview('');
                setProgress(0);
                setDuration('00:00');
                setUploadSpeed(0);
                setEstimatedTime(0);
                setUploadStartTime(null);

                alert('Video başarıyla yüklendi!');
            }
        } catch (error) {
            console.error('Video yükleme hatası:', error);
            setError('Video yüklenirken bir hata oluştu: ' + (error.response?.data?.message || error.message));
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-white rounded-xl shadow-lg p-6"
                >
                    <h1 className="text-2xl font-bold text-gray-900 mb-6">Video Yükle</h1>

                    {error && (
                        <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg">
                            <p className="text-red-600">{error}</p>
                        </div>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Video Başlığı *
                            </label>
                            <input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="input"
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Açıklama
                            </label>
                            <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                rows="4"
                                className="input"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Kategori *
                            </label>
                            <select
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                className="input"
                                required
                            >
                                <option value="">Kategori Seçin</option>
                                {categories.map((cat) => (
                                    <option key={cat._id} value={cat._id}>
                                        {cat.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Video Dosyası *
                            </label>
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-primary-500 transition-colors">
                                <div className="space-y-1 text-center">
                                    <CloudArrowUpIcon className="mx-auto h-12 w-12 text-gray-400" />
                                    <div className="flex text-sm text-gray-600">
                                        <label className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500">
                                            <span>Video Seç</span>
                                            <input
                                                type="file"
                                                accept="video/*"
                                                onChange={handleVideoChange}
                                                className="sr-only"
                                            />
                                        </label>
                                    </div>
                                    {videoFile && (
                                        <p className="text-sm text-gray-500">
                                            {videoFile.name} ({duration})
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Thumbnail
                            </label>
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-primary-500 transition-colors">
                                <div className="space-y-1 text-center">
                                    {thumbnailPreview ? (
                                        <div className="relative">
                                            <img
                                                src={thumbnailPreview}
                                                alt="Thumbnail önizleme"
                                                className="mx-auto h-32 w-auto rounded-lg"
                                            />
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setThumbnailFile(null);
                                                    setThumbnailPreview('');
                                                }}
                                                className="absolute -top-2 -right-2 text-red-500 hover:text-red-700"
                                            >
                                                <XCircleIcon className="h-6 w-6" />
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <CloudArrowUpIcon className="mx-auto h-12 w-12 text-gray-400" />
                                            <div className="flex text-sm text-gray-600">
                                                <label className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500">
                                                    <span>Thumbnail Seç</span>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleThumbnailChange}
                                                        className="sr-only"
                                                    />
                                                </label>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        {uploading && (
                            <div className="relative pt-1">
                                <div className="flex mb-2 items-center justify-between">
                                    <div>
                                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-primary-600 bg-primary-200">
                                            Yükleniyor
                                        </span>
                                    </div>
                                    <div className="text-right">
                                        <span className="text-xs font-semibold inline-block text-primary-600">
                                            {progress}%
                                        </span>
                                    </div>
                                </div>
                                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-primary-200">
                                    <div
                                        style={{ width: `${progress}%` }}
                                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-500 transition-all duration-500"
                                    />
                                </div>
                                <div className="text-xs text-gray-600 space-y-1">
                                    <p>Yükleme Hızı: {formatBytes(uploadSpeed)}/s</p>
                                    <p>Tahmini Kalan Süre: {formatTime(estimatedTime)}</p>
                                    <p>Yüklenen: {formatBytes(videoFile?.size * (progress / 100))} / {formatBytes(videoFile?.size)}</p>
                                </div>
                            </div>
                        )}

                        <div className="flex justify-end">
                            <button
                                type="submit"
                                disabled={uploading}
                                className={`btn btn-primary ${
                                    uploading ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                            >
                                {uploading ? 'Yükleniyor...' : 'Video Yükle'}
                            </button>
                        </div>
                    </form>
                </motion.div>
            </div>
        </div>
    );
}

export default VideoUploadPage; 