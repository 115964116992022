import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../utils/axios';

const VideoPage = () => {
  const { id } = useParams();
  const videoRef = useRef(null);
  const [video, setVideo] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      setError('Video ID bulunamadı');
      setLoading(false);
      return;
    }

    const fetchVideo = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await axios.get(`/videos/${id}`);
        setVideo(response.data);
        
        // Video yüklendiğinde önbelleğe al
        if (response.data.videoUrl) {
          const preloadVideo = new Audio(response.data.videoUrl);
          preloadVideo.preload = 'auto';
        }
      } catch (error) {
        console.error('Video yükleme hatası:', error);
        setError(error.response?.data?.message || 'Video yüklenirken bir hata oluştu');
      } finally {
        setLoading(false);
      }
    };

    fetchVideo();
  }, [id]);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Yükleniyor...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center p-4">{error}</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      {video && (
        <>
          <h1 className="text-2xl font-bold mb-4">{video.title}</h1>
          <div className="relative pt-[56.25%]">
            <video
              ref={videoRef}
              controls
              className="absolute top-0 left-0 w-full h-full"
              playsInline
              controlsList="nodownload"
              onError={(e) => setError('Video oynatılırken bir hata oluştu')}
            >
              <source src={video.videoUrl} type="video/mp4" />
              Tarayıcınız video elementini desteklemiyor.
            </video>
          </div>
          {video.description && (
            <p className="mt-4 text-gray-700">{video.description}</p>
          )}
        </>
      )}
    </div>
  );
};

export default VideoPage; 