import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Switch,
    FormControlLabel,
    Chip,
    Tooltip,
    Alert,
    Snackbar,
    CircularProgress
} from '@mui/material';
import {
    PersonAdd,
    Delete,
    Edit,
    Save,
    Close,
    Refresh
} from '@mui/icons-material';
import axios from '../utils/axios';

const UserManagementPage = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        isAdmin: false,
        isActive: true
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/users/admin');
            setUsers(response.data);
        } catch (error) {
            console.error('Kullanıcılar yüklenirken hata oluştu:', error);
            showSnackbar('Kullanıcılar yüklenirken bir hata oluştu', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleAddUser = async () => {
        try {
            const response = await axios.post('/users', formData);
            if (response.data) {
                setUsers([response.data, ...users]);
                setOpenDialog(false);
                resetForm();
                showSnackbar('Kullanıcı başarıyla eklendi', 'success');
            }
        } catch (error) {
            console.error('Kullanıcı eklenirken hata oluştu:', error);
            showSnackbar(error.response?.data?.message || 'Kullanıcı eklenirken bir hata oluştu', 'error');
        }
    };

    const handleEditUser = async () => {
        try {
            const response = await axios.put(`/users/${editUser._id}`, formData);
            if (response.data) {
                setUsers(users.map(user => 
                    user._id === editUser._id ? response.data : user
                ));
                setOpenDialog(false);
                resetForm();
                showSnackbar('Kullanıcı başarıyla güncellendi', 'success');
            }
        } catch (error) {
            console.error('Kullanıcı güncellenirken hata oluştu:', error);
            showSnackbar(error.response?.data?.message || 'Kullanıcı güncellenirken bir hata oluştu', 'error');
        }
    };

    const handleDeleteUser = async (userId) => {
        if (window.confirm('Bu kullanıcıyı silmek istediğinizden emin misiniz?')) {
            try {
                const response = await axios.delete(`/users/${userId}`);
                if (response.data.success) {
                    setUsers(users.filter(user => user._id !== userId));
                    showSnackbar('Kullanıcı başarıyla silindi', 'success');
                }
            } catch (error) {
                console.error('Kullanıcı silinirken hata oluştu:', error);
                showSnackbar(error.response?.data?.message || 'Kullanıcı silinirken bir hata oluştu', 'error');
            }
        }
    };

    const handleToggleStatus = async (userId) => {
        try {
            const response = await axios.patch(`/users/${userId}/toggle-status`);
            if (response.data) {
                setUsers(users.map(user =>
                    user._id === userId ? response.data : user
                ));
                showSnackbar(
                    `Kullanıcı durumu başarıyla değiştirildi`,
                    'success'
                );
            }
        } catch (error) {
            console.error('Kullanıcı durumu değiştirilirken hata oluştu:', error);
            showSnackbar(error.response?.data?.message || 'Kullanıcı durumu değiştirilirken bir hata oluştu', 'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: name === 'isAdmin' || name === 'isActive' ? checked : value
        }));
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const resetForm = () => {
        setFormData({
            name: '',
            email: '',
            password: '',
            isAdmin: false,
            isActive: true
        });
        setEditUser(null);
    };

    const handleOpenDialog = (user = null) => {
        if (user) {
            setEditUser(user);
            setFormData({
                name: user.name || '',
                email: user.email || '',
                password: '',
                isAdmin: user.isAdmin || false,
                isActive: user.isActive !== undefined ? user.isActive : true
            });
        } else {
            resetForm();
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        resetForm();
    };

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    Kullanıcı Yönetimi
                </Typography>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PersonAdd />}
                        onClick={() => handleOpenDialog()}
                        sx={{ mr: 2 }}
                    >
                        Yeni Kullanıcı
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Refresh />}
                        onClick={fetchUsers}
                        disabled={loading}
                    >
                        Listeyi Yenile
                    </Button>
                </Box>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Ad Soyad</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>E-posta</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Rol</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Durum</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>İşlemler</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        <Typography variant="body1" sx={{ py: 4, color: 'text.secondary' }}>
                                            Henüz kullanıcı bulunmuyor
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                users.map((user) => (
                                    <TableRow key={user._id} hover>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>
                                            <Chip
                                                label={user.isAdmin ? 'Admin' : 'Kullanıcı'}
                                                color={user.isAdmin ? 'primary' : 'default'}
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={user.isActive ? 'Aktif' : 'Pasif'}
                                                color={user.isActive ? 'success' : 'error'}
                                                size="small"
                                                onClick={() => handleToggleStatus(user._id)}
                                                sx={{ cursor: 'pointer' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Düzenle">
                                                <IconButton 
                                                    color="primary"
                                                    onClick={() => handleOpenDialog(user)}
                                                    size="small"
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sil">
                                                <span>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => handleDeleteUser(user._id)}
                                                        size="small"
                                                        disabled={user.email === 'data@knowhy.co'}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {editUser ? 'Kullanıcı Düzenle' : 'Yeni Kullanıcı Ekle'}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            label="Ad Soyad"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="E-posta"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Şifre"
                            name="password"
                            type="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            margin="normal"
                            required={!editUser}
                            helperText={editUser ? 'Şifreyi değiştirmek istemiyorsanız boş bırakın' : ''}
                        />
                        <Box sx={{ mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.isAdmin}
                                        onChange={handleInputChange}
                                        name="isAdmin"
                                        disabled={formData.email === 'data@knowhy.co'}
                                    />
                                }
                                label="Admin Yetkisi"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.isActive}
                                        onChange={handleInputChange}
                                        name="isActive"
                                        disabled={formData.email === 'data@knowhy.co'}
                                    />
                                }
                                label="Aktif"
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} startIcon={<Close />}>
                        İptal
                    </Button>
                    <Button
                        onClick={editUser ? handleEditUser : handleAddUser}
                        variant="contained"
                        color="primary"
                        startIcon={<Save />}
                    >
                        {editUser ? 'Güncelle' : 'Kaydet'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default UserManagementPage; 