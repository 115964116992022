import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from '../utils/axios';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const response = await axios.post('/auth/login', { email, password });
            const { token, user } = response.data;

            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            navigate(user.isAdmin ? '/admin/videos' : '/');
        } catch (error) {
            setError(error.response?.data?.message || 'Giriş başarısız');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-[#1a237e] flex items-center justify-center p-4">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="w-full max-w-md"
            >
                <div className="bg-[#1e2993] rounded-lg shadow-xl p-8">
                    <motion.div
                        initial={{ scale: 0.9 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.3 }}
                        className="text-center mb-8"
                    >
                        <h1 className="text-4xl font-bold text-[#42a5f5] mb-2">Knowhy</h1>
                        <p className="text-blue-200">Şirket İçi Personel Video Eğitim Platformu</p>
                    </motion.div>

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <motion.input
                                whileFocus={{ scale: 1.02 }}
                                type="text"
                                placeholder="E-posta / Şirket ID"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-4 py-3 rounded-lg bg-[#283593] text-white placeholder-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                                required
                            />
                        </div>

                        <div>
                            <motion.input
                                whileFocus={{ scale: 1.02 }}
                                type="password"
                                placeholder="Şifre"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full px-4 py-3 rounded-lg bg-[#283593] text-white placeholder-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                                required
                            />
                        </div>

                        {error && (
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                className="text-red-400 text-sm text-center"
                            >
                                {error}
                            </motion.div>
                        )}

                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            type="submit"
                            disabled={loading}
                            className="w-full py-3 rounded-lg bg-[#42a5f5] text-white font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200"
                        >
                            {loading ? 'Giriş yapılıyor...' : 'Giriş Yap'}
                        </motion.button>

                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            className="text-center"
                        >
                            <a href="#" className="text-sm text-blue-300 hover:text-blue-400 transition duration-200">
                                Sevgiyle ve Sövgüyle Yapıldı ❤️
                            </a>
                        </motion.div>
                    </form>
                </div>
            </motion.div>
        </div>
    );
};

export default LoginPage; 