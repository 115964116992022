import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Button,
    TextField,
    Chip,
    Tooltip,
    Alert,
    Snackbar,
    CircularProgress
} from '@mui/material';
import {
    CheckCircle,
    Cancel,
    Delete,
    Edit,
    Save,
    Close,
    Refresh
} from '@mui/icons-material';
import axios from '../utils/axios';

const CommentManagementPage = () => {
    const [comments, setComments] = useState([]);
    const [editingComment, setEditingComment] = useState(null);
    const [editContent, setEditContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        fetchComments();
    }, []);

    const fetchComments = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/comments/admin');
            setComments(response.data);
        } catch (error) {
            console.error('Yorumlar yüklenirken hata oluştu:', error);
            showSnackbar('Yorumlar yüklenirken bir hata oluştu', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleToggleApproval = async (commentId, currentStatus) => {
        try {
            const response = await axios.patch(`/comments/${commentId}/toggle-approval`);
            if (response.data) {
                setComments(comments.map(comment =>
                    comment._id === commentId ? response.data : comment
                ));
                showSnackbar(
                    `Yorum ${currentStatus ? 'reddedildi' : 'onaylandı'}`,
                    'success'
                );
            }
        } catch (error) {
            console.error('Yorum onay durumu değiştirilirken hata oluştu:', error);
            showSnackbar('Yorum onay durumu değiştirilirken bir hata oluştu', 'error');
        }
    };

    const handleDeleteComment = async (commentId) => {
        if (window.confirm('Bu yorumu silmek istediğinizden emin misiniz?')) {
            try {
                const response = await axios.delete(`/comments/${commentId}`);
                if (response.data.success) {
                    setComments(comments.filter(comment => comment._id !== commentId));
                    showSnackbar('Yorum başarıyla silindi', 'success');
                }
            } catch (error) {
                console.error('Yorum silinirken hata oluştu:', error);
                showSnackbar('Yorum silinirken bir hata oluştu', 'error');
            }
        }
    };

    const handleEditClick = (comment) => {
        setEditingComment(comment);
        setEditContent(comment.content);
    };

    const handleEditSubmit = async () => {
        try {
            const response = await axios.put(`/comments/${editingComment._id}`, {
                content: editContent
            });
            if (response.data) {
                setComments(comments.map(comment =>
                    comment._id === editingComment._id ? response.data : comment
                ));
                setEditingComment(null);
                showSnackbar('Yorum başarıyla düzenlendi', 'success');
            }
        } catch (error) {
            console.error('Yorum düzenlenirken hata oluştu:', error);
            showSnackbar('Yorum düzenlenirken bir hata oluştu', 'error');
        }
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    Yorum Yönetimi
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchComments}
                    startIcon={<Refresh />}
                    disabled={loading}
                >
                    Listeyi Yenile
                </Button>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Kullanıcı</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Video</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Yorum</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Tarih</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Durum</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>İşlemler</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {comments.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <Typography variant="body1" sx={{ py: 4, color: 'text.secondary' }}>
                                            Henüz yorum yapılmamış
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                comments.map((comment) => (
                                    <TableRow key={comment._id} hover>
                                        <TableCell>
                                            <Typography variant="subtitle2">
                                                {comment.user?.name || 'Silinmiş Kullanıcı'}
                                            </Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {comment.user?.email}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {comment.video?.title || 'Silinmiş Video'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ maxWidth: 300 }}>
                                            {editingComment?._id === comment._id ? (
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={2}
                                                    value={editContent}
                                                    onChange={(e) => setEditContent(e.target.value)}
                                                    size="small"
                                                    sx={{ my: 1 }}
                                                />
                                            ) : (
                                                <Typography sx={{ wordBreak: 'break-word' }}>
                                                    {comment.content}
                                                </Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {formatDate(comment.createdAt)}
                                            </Typography>
                                            {comment.isEdited && (
                                                <Typography variant="caption" color="textSecondary">
                                                    (Düzenlendi)
                                                </Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={comment.isApproved ? 'Onaylı' : 'Onay Bekliyor'}
                                                color={comment.isApproved ? 'success' : 'warning'}
                                                size="small"
                                                sx={{ fontWeight: 'medium' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Tooltip title={comment.isApproved ? 'Onayı Kaldır' : 'Onayla'}>
                                                    <IconButton
                                                        onClick={() => handleToggleApproval(comment._id, comment.isApproved)}
                                                        color={comment.isApproved ? 'error' : 'success'}
                                                        size="small"
                                                    >
                                                        {comment.isApproved ? <Cancel /> : <CheckCircle />}
                                                    </IconButton>
                                                </Tooltip>
                                                {editingComment?._id === comment._id ? (
                                                    <>
                                                        <Tooltip title="Kaydet">
                                                            <IconButton
                                                                onClick={handleEditSubmit}
                                                                color="primary"
                                                                size="small"
                                                            >
                                                                <Save />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="İptal">
                                                            <IconButton
                                                                onClick={() => setEditingComment(null)}
                                                                color="inherit"
                                                                size="small"
                                                            >
                                                                <Close />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                ) : (
                                                    <Tooltip title="Düzenle">
                                                        <IconButton
                                                            onClick={() => handleEditClick(comment)}
                                                            color="primary"
                                                            size="small"
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <Tooltip title="Sil">
                                                    <IconButton
                                                        onClick={() => handleDeleteComment(comment._id)}
                                                        color="error"
                                                        size="small"
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default CommentManagementPage; 