import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
    Snackbar
} from '@mui/material';
import axios from '../utils/axios';

const CategoryManagementPage = () => {
    const [categories, setCategories] = useState([]);
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        const checkAuthAndFetchCategories = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                showSnackbar('Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.', 'error');
                window.location.href = '/login';
                return;
            }

            try {
                // Token'ın geçerliliğini kontrol et
                await axios.get('/auth/verify');
                fetchCategories();
            } catch (error) {
                if (error.response?.status === 401) {
                    localStorage.removeItem('token');
                    showSnackbar('Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.', 'error');
                    window.location.href = '/login';
                }
            }
        };

        checkAuthAndFetchCategories();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    const fetchCategories = async () => {
        try {
            const response = await axios.get('/categories');
            if (Array.isArray(response.data)) {
                setCategories(response.data);
            } else {
                console.error('Beklenmeyen veri formatı:', response.data);
                setCategories([]);
            }
        } catch (error) {
            console.error('Kategoriler yüklenirken hata oluştu:', error);
            showSnackbar('Kategoriler yüklenirken hata oluştu: ' + (error.response?.data?.message || error.message), 'error');
            setCategories([]);
        }
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const handleOpen = (category = null) => {
        if (category) {
            setEditMode(true);
            setSelectedCategory(category);
            setFormData({
                name: category.name,
                description: category.description || ''
            });
        } else {
            setEditMode(false);
            setSelectedCategory(null);
            setFormData({ name: '', description: '' });
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({ name: '', description: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!formData.name.trim()) {
            showSnackbar('Kategori adı boş olamaz', 'error');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                showSnackbar('Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.', 'error');
                setTimeout(() => {
                    window.location.href = '/login';
                }, 2000);
                return;
            }

            // Token'ı kontrol et
            try {
                await axios.get('/auth/verify');
            } catch (error) {
                if (error.response?.status === 401) {
                    localStorage.removeItem('token');
                    showSnackbar('Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.', 'error');
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 2000);
                    return;
                }
            }

            let response;
            if (editMode && selectedCategory) {
                response = await axios.put(`/categories/${selectedCategory._id}`, formData);
                if (response.data) {
                    showSnackbar('Kategori başarıyla güncellendi', 'success');
                }
            } else {
                response = await axios.post('/categories', formData);
                if (response.data) {
                    showSnackbar('Kategori başarıyla eklendi', 'success');
                }
            }

            await fetchCategories();
            handleClose();
        } catch (error) {
            console.error('Kategori işlemi sırasında hata oluştu:', error);
            
            if (error.response?.status === 401) {
                showSnackbar('Yetkilendirme hatası. Lütfen tekrar giriş yapın.', 'error');
                localStorage.removeItem('token');
                setTimeout(() => {
                    window.location.href = '/login';
                }, 2000);
            } else if (error.response?.status === 403) {
                showSnackbar('Bu işlem için admin yetkisine sahip değilsiniz.', 'error');
            } else {
                showSnackbar(
                    `Kategori işlemi sırasında hata oluştu: ${error.response?.data?.message || error.message}`,
                    'error'
                );
            }
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Bu kategoriyi silmek istediğinizden emin misiniz?')) {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    showSnackbar('Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.', 'error');
                    window.location.href = '/login';
                    return;
                }

                await axios.delete(`/categories/${id}`);
                showSnackbar('Kategori başarıyla silindi');
                fetchCategories();
            } catch (error) {
                console.error('Kategori silinirken hata oluştu:', error);
                showSnackbar(`Kategori silinirken hata oluştu: ${error.response?.data?.message || error.message}`, 'error');
            }
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" gutterBottom>
                Kategori Yönetimi
            </Typography>
            
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpen()}
                sx={{ mb: 3 }}
            >
                Yeni Kategori Ekle
            </Button>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Kategori Adı</TableCell>
                            <TableCell>Açıklama</TableCell>
                            <TableCell>İşlemler</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.map((category) => (
                            <TableRow key={category._id}>
                                <TableCell>{category.name}</TableCell>
                                <TableCell>{category.description}</TableCell>
                                <TableCell>
                                    <Button
                                        color="primary"
                                        onClick={() => handleOpen(category)}
                                        sx={{ mr: 1 }}
                                    >
                                        Düzenle
                                    </Button>
                                    <Button
                                        color="error"
                                        onClick={() => handleDelete(category._id)}
                                    >
                                        Sil
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {editMode ? 'Kategori Düzenle' : 'Yeni Kategori Ekle'}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Kategori Adı"
                        fullWidth
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Açıklama"
                        fullWidth
                        multiline
                        rows={4}
                        value={formData.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>İptal</Button>
                    <Button onClick={handleSubmit} color="primary">
                        {editMode ? 'Güncelle' : 'Ekle'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default CategoryManagementPage; 